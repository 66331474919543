#quill-container {
  height: 600px;
  margin-bottom: 60px;
  min-height: 100%;
}
#quill-container-tests {
  height: 350px;
  margin-bottom: 60px;
  min-height: 100%;
}

.editor {
  height: 100%;
}

.multimedia-recorder {
  height: 600px;
}

.input-range__label-container {
  color: black;
  font-size: 10px;
  font-family: "Lato", sans-serif;
}
